.instagram-wrapper {
  display: flex;
  flex-direction: column;
  /* margin: 20px; */
  padding: 40px;
}

.instagram-title {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 10px;
}

.instagram-icons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btn-style {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

@media (min-width: 778px) {
  .instagram-wrapper {
    padding: 55px 6%;
    /* margin: 25px 6%; */
  }

  .instagram-title {
    font-size: 25px;
  }

  .instagram-icons-wrapper {
    justify-content: flex-start;
    flex-direction: row;
    gap: 5%;
  }

  .btn-style {
    display: flex;
    padding-left: 10px;
    padding-top: 10px;
    justify-content: flex-start;
    margin: 0 0 0 10px;
  }
}
