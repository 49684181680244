.signup-form-wrapper {
  padding: 20px;
  color: white;
}

.email-submit-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: space-between;
}

.email-form-element-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  resize: none;
}

.email-general-input-area {
  border-radius: 8px;
  line-height: 25px;
  padding: 4px 5px;
  border: 1px black solid;
  margin-top: 5px;

  font-size: 14px;
  background-color: #9569cf;
  color: white;
}

.text-area-input-area {
  width: 100%;
  resize: none;
}

.email-signup-btn-submit {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.email-signup-btn-text {
  margin-bottom: 25px;

  color: white;
  font-size: 18px;
}

.email-signup-btn {
  margin-left: 10px;
}
